<template>
    <b-card no-body>
        <div class="p-1">
            <div class="row">
                <div class="col-md-4 mb-1">
                    <label class="form-label">Tarih Aralığı</label>
                    <v-date-picker v-model="date" range placeholder="Tarih Aralığı"></v-date-picker>        
                </div>
                <!-- <div class="col-md-4 mb-1">
                    <label class="form-label">Taşımacı Cari</label>
                    <v-select-list v-model="cari" :options="cariList" label="CARI_ISIM" v-on:search="getCariList" placeholder="Taşımacı Cari" :loading="cariLoading"></v-select-list>
                </div> -->
                <div class="col-md-4 mb-1">
                    <label class="form-label">Bitiş Firma</label>
                    <v-select-list v-model="targetCari" :options="targetCariList" label="CARI_ISIM" v-on:search="getTargetCariList" placeholder="Bitiş Firma" :loading="targetCariLoading"></v-select-list>
                </div>
                <div class="col-md-4 mb-1">
                    <label class="form-label">Stok</label>
                    <v-select-list v-model="stok" :options="stoks" label="Name"  placeholder="Stok" :loading="stokLoading"></v-select-list>
                </div>
                <!-- <div class="col-md-4 mb-1">
                    <label class="form-label">Şube</label>
                    <v-select-list v-model="sube" :options="subeList" label="BranchName" placeholder="Şube"></v-select-list>
                </div> -->
                <!-- <div class="col-md-4 mb-1">
                    <label class="form-label">Bölge</label>
                    <v-select-list v-model="transferRegion" :options="transferRegionList" label="RegionName" placeholder="Bölge"></v-select-list>
                </div> -->
            </div>
        
            <div class="row justify-content-end">
                <div class="col-auto mb-1 mb-md-0">
                    <v-executer :loading="filterLoading" :action="filter">Filtrele</v-executer>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-sm table-bordered bordered-header align-middle">
                <thead>
                    <tr>
                        <th rowspan="2" class="align-middle w-15">Stok Kodu</th>
                        <th rowspan="2" class="align-middle w-15">Stok Adı</th>
                        <th rowspan="2" class="align-middle w-15">Sube</th>
                        <th rowspan="2" class="align-middle w-55">Cari</th>
                        <template v-for="i in maxSums">
                            <th colspan="2" class="text-center">{{ i }}</th>
                        </template>
                    </tr>
                    <tr>
                        <template v-for="i in maxSums">
                            <th class="text-nowrap text-right">Toplam Taşıma</th>
                            <th class="text-nowrap text-right">Toplam Tonaj</th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(s,i) in sefers">
                        <template v-for="(ii) in getMaxCariCount(s)">
                            <tr :class="{'border-top-3':i != 0 && ii == 1}">
                                <td v-if="ii == 1" :rowspan="getMaxCariCount(s)" class="text-nowrap font-weight-bolder">{{ s.StokKodu }}</td>
                                <td v-if="ii == 1" :rowspan="getMaxCariCount(s)" class="text-nowrap font-weight-bolder">{{ s.StokAdi }}</td>
                                <template v-for="ms in maxSums">
                                    <td class="text-nowrap">{{ $safe(s,`ReportItem.${ms-1}.ReportItem.${ii - 1}.Sube`) }}</td>
                                    <td class="text-nowrap">{{ $safe(s,`ReportItem.${ms-1}.ReportItem.${ii - 1}.CariIsim`) }}</td>
                                    <td class="text-right">{{ $safe(s,`ReportItem.${ms-1}.ReportItem.${ii - 1}.TasimaSayisi`) }}</td>
                                    <td class="text-right">{{ $safe(s,`ReportItem.${ms-1}.ReportItem.${ii - 1}.TasimaToplamTonaj`) }}</td>
                                </template>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="4"></td>
                            <template v-for="ms in maxSums">
                                <td class="bg-light-warning font-weight-bolder text-right">{{ $safe(s,`ReportItem.${ms-1}.TotalCountTasimaSayisi`) }}</td>
                                <td class="bg-light-warning font-weight-bolder text-right">{{ $safe(s,`ReportItem.${ms-1}.TotalTonnage`) }}</td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-card>
</template>

<script>
import { BBadge, BCard,BFormCheckbox } from 'bootstrap-vue'
import { NetsisApi } from '@/api/netsis'
import { TransferRegionApi } from '@/api/transfer-region'
import { MaterialApi } from '@/api/material'
import moment from 'moment'

export default {
    components:{
        BCard,
        BBadge,
        BFormCheckbox
    },
    data(){
        return{
            queryData:{
                StartDate: moment().startOf('month').format('YYYY-MM-DD'),
                EndDate:moment().endOf('month').format("YYYY-MM-DD"),
                CariCode:null,
                TargetCariCode:null,
                StockCode:null,
                BranchCode:null,
                RegionCode:null
            },
            //Query data değişebilir ancak filtrelenmeyebilir. Filtrelenmediği zaman querydata üzerinden ref. kod oluşursa hatalı bir çalışma olur.
            filteredData:{
                StartDate: moment().startOf('month').format('YYYY-MM-DD'),
                EndDate:moment().endOf('month').format("YYYY-MM-DD"),
                CariCode:null,
                TargetCariCode:null,
                StockCode:null,
                BranchCode:null
            },
            cariList:[],
            targetCariList:[],
            cariLoading:false,
            targetCariLoading:false,
            sefers:[],
            stoks:[],
            filterLoading:false,
            stokLoading:false,
            createIrsaliyeLoading:false,
            subeList:[],
            subeLoading:false,
            transferRegionList:[],
            transferRegionLoading:false,
        }
    },
    mounted(){
        this.getSubeList()
        this.getRegionList()
        this.getStoks()
    },
    methods:{
        getRegionList(){
            this.transferRegionLoading = true
            TransferRegionApi.List().then(res =>{
                this.transferRegionList = res.data.Data
                this.transferRegionLoading =false
            }).catch(err => {
                this.transferRegionLoading =false
            })
        },
        getSubeList(){
            this.subeLoading = true
            NetsisApi.GetSubeList().then(res =>{
                if(res.data.Success){
                    this.subeList = res.data.Data
                }
                this.subeLoading = false
            }).catch(err=>{
                this.subeLoading = false
            })
        },
        filter(){
            this.filteredData.StartDate=this.queryData.StartDate
            this.filteredData.EndDate=this.queryData.EndDate,
            this.filteredData.CariCode=this.queryData.CariCode,
            this.filteredData.TargetCariCode=this.queryData.TargetCariCode,
            this.filteredData.StockCode=this.queryData.StockCode,
            this.filteredData.BranchCode=this.queryData.BranchCode
            this.filteredData.RegionCode = this.queryData.RegionCode
            this.filterLoading = true
            NetsisApi.GetSeferListReport(this.filteredData).then(res => {
                if(!res.data.Success){
                    this.$result(res)
                }else{
                    this.sefers = res.data.Data
                }
                this.filterLoading = false
            }).catch(err=> {
                console.log(err)
                this.filterLoading = false
            })
        },
        getStoks(){
            this.stokLoading = true
            MaterialApi.List().then(res => {
                this.stoks = res.data.Data
                this.stokLoading = false
            }).catch(err=> {
                console.log(err)
                this.stokLoading = false
            })
        },
        getCariList(searchKey){
            if(searchKey){
                this.cariLoading = true
                NetsisApi.CariList(searchKey).then(res => {
                    this.cariList = res.data.Data
                    this.cariLoading = false
                }).catch(err=> {
                    console.log(err)
                    this.cariLoading = false
                })
            }
        },
        getTargetCariList(searchKey){
            if(searchKey){
                this.targetCariLoading = true
                NetsisApi.CariList(searchKey).then(res => {
                    this.targetCariList = res.data.Data
                    this.targetCariLoading = false
                }).catch(err=> {
                    console.log(err)
                    this.targetCariLoading = false
                })
            }
        },
        getCurrentMonthDateRange(){
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return [firstDay,lastDay]
        },
        getMaxCariCount(s){
            return Math.max(s.ReportItem.map(r => r.ReportItem.length))
        }
    },
    computed:{
        transferRegion:{
            get:function(){
                return this.transferRegionList.find(s=>s.RegionCode==this.queryData.RegionCode)
            },
            set:function(value){
                this.queryData.RegionCode = value?.RegionCode || null
            }
        },
        sube:{
            get:function(){
                return this.subeList.find(s=>s.BranchCode==this.queryData.BranchCode)
            },
            set:function(value){
                this.queryData.BranchCode = value?.BranchCode || null
            }
        },
        date:{
            get:function(){
                return [this.queryData.StartDate,this.queryData.EndDate].filter(d=>d != null)
            },
            set:function(val){
                this.queryData.StartDate = val[0] || null
                this.queryData.EndDate = val[1] || null
            }
        },
        cari:{
            get:function(){
                return this.cariList.find(s=>s.CARI_KOD==this.queryData.CariCode)
            },
            set:function(value){
                this.queryData.CariCode = value?.CARI_KOD || null
            }
        },
        targetCari:{
            get:function(){
                return this.targetCariList.find(s=>s.CARI_KOD==this.queryData.TargetCariCode)
            },
            set:function(value){
                this.queryData.TargetCariCode = value?.CARI_KOD || null
            }
        },
        stok:{
            get:function(){
                return this.stoks.find(s=>s.Id==this.queryData.Id)
            },
            set:function(value){
                this.queryData.StockCode = value?.Code || null
            }
        },
        maxSums(){
            return Math.max(...(this.sefers || []).map(s => s.ReportItem.length),1)
        }
    }
}
</script>

<style>
.border-top-3{
    border-top: 3px solid #ebe9f1 !important;
}
.dark-layout .border-top-3{
    border-top: 3px solid #3b4253 !important;
}
</style>